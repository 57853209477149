<template>
  <div class="logistics-box">
    <div class="ivu-card">
      <div class="card-laft">{{ $t('logisticsInfo.wlxq') }}</div>
    </div>
    <div class="logistics-info">
      <el-timeline v-loading="loading" :reverse="reverse">
        <el-timeline-item v-for="(activity, index) in activities" :key="index" :timestamp="activity.time">
          {{ activity.context }}
        </el-timeline-item>
      </el-timeline>
    </div>
    <div v-if="!noData" class="companyInfo">
      <span>{{ $t('logisticsInfo.ydhm') }}：{{ courierNum }}</span>
      <span>{{ $t('logisticsInfo.wlgs') }}：{{ courierCompanyInfo.com_name }}</span>
      <span>{{ $t('logisticsInfo.kfdh') }}：{{ courierCompanyInfo.phone }}</span>
      <p>{{ $t('logisticsInfo.wlgsgfwz') }}：<a :href="courierCompanyInfo.url" target="_blank" rel="noopener noreferrer">{{
        courierCompanyInfo.url }}</a></p>
      <img :src="courierCompanyInfo.imgUrl" alt="">
    </div>
    <div class="ivu-card">
      <div class="card-laft">{{ $t('orderBox.wldt') }}</div>
    </div>
    <div style="height:50px">
      <el-steps :active="1" direction="vertical">
        <el-step v-for="res in JSON.parse(dataList)" :key="res.id" :title="res.time" :description="res.status"></el-step>
      </el-steps>
    </div>

  </div>
</template>

<script>
export default {
  name: 'BbjtwebsiteLogisticsInfo',

  data() {
    return {
      appid: '1139228', //id
      sign: '47af1e8a5fd1443a9f5e271c0660d4c4',//密钥
      courierNum: '',//快递单号
      phone: this.$route.query.phone, //手机号
      activities: [],
      reverse: true,
      loading: true,
      courierCompanyInfo: {},
      noData: false,
      dataList: [{}]
    };
  },


  mounted() {
    this.geTlogisticsSingle()
    this.logisticSdynamics()
    console.log(3)
    console.log(this.$route.query)
  },

  methods: {
    // 动态物流
    async logisticSdynamics() {
      let result = await this.api.get(this.apiPath.logisticSdynamics + "/124822985071656960/780098068058")
      // let result = await this.api.get(this.apiPath.logisticSdynamics+"/"+this.$route.query.order_no +"/"+this.$route.query.logistics_no)
      console.log(result)
      this.dataList = result.logistics_trends
      console.log(this.dataList)
      // console.log(2)
    },

    async geTlogisticsSingle() {
      //通过订单号查询物流单号
      let data = {
        // xsdh: '31383517715759104'
        xsdh: this.$route.query.order_no,
      }
      let res = await this.api.post(this.apiPath.transfer, data);
      if (res.data.length) {
        this.courierNum = res.data[0].wl_all[0].wldh
        this.courierName()
        this.noData = false
        this.loading = false
      } else {
        this.noData = true
        this.$message.error(this.$t('logisticsInfo.wlgsgfwz'));
        this.loading = false
      }
    },
    courierName() {
      //查询快递公司信息
      let _this = this
      let xhr = new XMLHttpRequest(); // 创建XHR对象
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) { // 4表示此次请求结束
          let result = JSON.parse(xhr.responseText);// 后端返回的结果为字符串，这里将结果转换为json
          console.log(result);
          _this.courierCompanyInfo = result.showapi_res_body.data[0]
          _this.logisticsInfo(result.showapi_res_body.data[0].simpleName)
        }
      };
      xhr.open( // 打开链接
        "post",
        "https://route.showapi.com/64-21", // 后端地址
        true
      );
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded"); // 设置请求头
      xhr.send( // 设置需要携带到后端的字段，字符串形式
        "showapi_timestamp=" + this.formatterDateTime() +
        "&showapi_appid=" + this.appid + // id
        "&showapi_sign=" + this.sign + // 密钥
        "&nu=" + this.courierNum + // 快递单号
        "&addOther=" + "`"
      );
    },
    logisticsInfo(com) {
      //查询物流信息
      let _this = this
      let xhr = new XMLHttpRequest(); // 创建XHR对象
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) { // 4表示此次请求结束
          let result = JSON.parse(xhr.responseText);// 后端返回的结果为字符串，这里将结果转换为json
          console.log(result);
          _this.activities = result.showapi_res_body.data
          if (!result.showapi_res_body.data.length) {
            _this.$message.error(result.showapi_res_body.msg);
            _this.loading = false
          }
        }
      };
      xhr.open( // 打开链接
        "post",
        "https://route.showapi.com/64-34?", // 后端地址
        true
      );
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded"); // 设置请求头
      xhr.send( // 设置需要携带到后端的字段，字符串形式
        "showapi_timestamp=" + this.formatterDateTime() +
        "&showapi_appid=" + this.appid + // id
        "&showapi_sign=" + this.sign + // 密钥
        "&com=" + com + // 快递名称
        "&nu=" + this.courierNum +// 快递单号
        "&phone=" + this.phone
      );
    },
    formatterDateTime() {
      var date = new Date()
      var month = date.getMonth() + 1
      var datetime = date.getFullYear()
        + ""// "年"
        + (month >= 10 ? month : "0" + month)
        + ""// "月"
        + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate())
        + ""
        + (date.getHours() < 10 ? "0" + date.getHours() : date.getHours())
        + ""
        + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes())
        + ""
        + (date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds());
      return datetime;
    }
  },
};
</script>

<style lang="less" scoped>
.logistics-box {
  width: 60%;
  padding: 40px;

  .ivu-card {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .card-laft {
      border-left: 3px solid #7F0114;
      padding-left: 10px;
      font-size: 15px;
    }
  }

  .logistics-info {
    border-bottom: 2px solid #eee;
  }

  .companyInfo {
    margin-top: 20px;

    span {
      display: inline-block;
      margin-right: 20px;
    }

    a {
      color: blue;
    }

    img {
      margin-top: 10px;
    }
  }
}
</style>